export interface HTTPException {
    status: number;
    statusText: string;
    reason: string;
}

export class APIError implements HTTPException {
    public status: number;
    public statusText: string;
    public reason: string;
    
    constructor(http_error: HTTPException) {
        this.status = http_error.status;
        this.statusText = http_error.statusText;
        this.reason = http_error.reason;
    }
}

export interface ErrorState {
    status_text: string;
    reason: string;
}

export interface ErrorNavigateState {
    path: string;
    state: ErrorState;
}

export class UnknownError implements HTTPException {
    constructor(public status: number, public statusText: string, public reason: string) {}
}