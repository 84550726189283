import React, { ReactNode, useEffect, useState } from "react";
import { FieldTemplateComponent, OutputOption, OutputQuestion, QuestionAnswer } from '../../api/api';
import Option from './option';
import './matching-options.css';

interface IMatchingOptions {
    question: OutputQuestion;
    form: [object, (e: React.FormEvent<HTMLInputElement>) => void];
    answers: Array<QuestionAnswer> | null;
}
const MatchingOptions: React.FunctionComponent<IMatchingOptions> = ({question, form, answers}) => {
    const [mappings, setMappings] = useState<Map<number,Map<string, Array<OutputOption>>>>(new Map<number,Map<string, Array<OutputOption>>>());
    const [ordered_blank_indices, setOrderedBlankIndices] = useState<[number, number]>([0,0]);
    const [form_state, handle_form_change] = form;
    const [correct_answers, setCorrectAnswers] = useState<Map<string,[number, number]> | null>(null);

    useEffect(() => {
        if (question.blank_indices.length > 0)
        {
            const mappings = map_blank_index_to_all_options(question);
            const ordered_blank_indices = order_blank_indices_by_dominance(mappings);
            const correct_answers = construct_correct_answers_lookup(question, ordered_blank_indices);

            setCorrectAnswers(correct_answers);
            setOrderedBlankIndices(ordered_blank_indices);
            setMappings(mappings);
        }
    }, [question]);

    const render_dominant = function(dominant_value: [string, OutputOption[]], dominant_field: FieldTemplateComponent): ReactNode {
        return (
        <div className="matching-legend">
            <legend><Option option={dominant_value[1][0]} field={dominant_field}/></legend>
        </div>);
    }

    const render_options = function(dominant: [string, OutputOption[]], non_dominant_options: [string, OutputOption[]][], non_dominant_field: FieldTemplateComponent) {
        return (
            <div className="matching-options">
                <div className="matching-options-container">
                    {non_dominant_options.map(option_value =>{
                        const id = `${dominant[0]};${option_value[0]}`;
                        const is_correct = correct_answers?.has(id)
                        const correct_ids = correct_answers?.get(id) || [dominant[1][0].question_option_id, option_value[1][0].question_option_id];
                        const name = `${dominant[0]};${option_value[0]}:${correct_ids.join(';')}:${is_correct?'t':'f'}`;
                        return (
                            <div key={id}>
                                <label htmlFor={id}>
                                    <div><input type="checkbox" value={form_state[name]} id={id} name={name} onChange={handle_form_change} disabled={answers ? true : false}/></div>
                                    <div><span><Option option={option_value[1][0]} field={non_dominant_field} /></span></div>
                                </label>
                                {answers && (()=>{
                                    const answered_correctly = is_correct === form_state[name];
                                    const missed = is_correct && !answered_correctly;
                                    return <div>
                                        <span className={answered_correctly ? 'correct':(missed ? 'missed':'incorrect')}>
                                            {is_correct ? (form_state[name] ? '✓':'✗'):(form_state[name] ? '✗': '')}
                                        </span>
                                    </div>;
                                })()}
                                {/*answers ? (
                                    <div><span className={`answer ${is_correct ? 'correct':'incorrect'}`}>{(is_correct ? '✓':(form_state[name] ? '✗': ''))}</span></div>
                                ):''*/}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    const render_row = function(dominant_value: [string, OutputOption[]], non_dominant_options: [string, OutputOption[]][], is_left_dominant: boolean, dominant_field: FieldTemplateComponent, non_dominant_field: FieldTemplateComponent): ReactNode {
        // Left dominant
        if (is_left_dominant) {
            return (
                <fieldset className="matching-fieldset" key={`${dominant_value[0]}-row`}>
                    <div className="matching-row">
                        {render_dominant(dominant_value, dominant_field)}
                        {render_options(dominant_value, non_dominant_options, non_dominant_field)}
                    </div>
                </fieldset>
            );
        }

        // Right dominant
        else {
            return (
                <fieldset className="matching-fieldset" key={`${dominant_value[0]}-row`}>
                    <div className="matching-row">
                        {render_options(dominant_value, non_dominant_options, non_dominant_field)}
                        {render_dominant(dominant_value, dominant_field)}
                    </div>
                </fieldset>
            );
        }
    }

    const render_header = function(dominant_field: FieldTemplateComponent, non_dominant_field: FieldTemplateComponent, is_left_dominant: boolean): ReactNode {
        if (is_left_dominant) {
            return (
                <div className="matching-row matching-header" key="header-row">
                    <div>
                        <h3>{dominant_field.field_name}</h3>
                    </div>
                    <div>
                        <h3>{non_dominant_field.field_name}</h3>
                    </div>
                </div>
            );
        }

        else {
            return (
                <div className="matching-row header"  key="header-row">
                    <div>
                        <h3>{non_dominant_field.field_name}</h3>
                    </div>
                    <div>
                        <h3>{dominant_field.field_name}</h3>
                    </div>
                </div>
            );
        }
    }

    const render_matching = function (): ReactNode {
        const rows = new Array<ReactNode>();
        if (mappings.size > 0){
            const dominant_options = [...(mappings.get(ordered_blank_indices[0])!.entries())];
            const non_dominant_options = [...(mappings.get(ordered_blank_indices[1])!.entries())].sort((tup_a, tup_b) => tup_a[0].localeCompare(tup_b[0]));
            const dominant_field = question.template_components[ordered_blank_indices[0]] as FieldTemplateComponent;
            const non_dominant_field = question.template_components[ordered_blank_indices[1]] as FieldTemplateComponent;
            const is_left_dominant = ordered_blank_indices[0] < ordered_blank_indices[1];
            rows.push(render_header(dominant_field, non_dominant_field, is_left_dominant));
            dominant_options.forEach(dominant_option => {
                rows.push(render_row(dominant_option, non_dominant_options, is_left_dominant, dominant_field, non_dominant_field));
            });
        }
        return rows;
    }

    return (
        <div className="matching-grid">
            {render_matching()}
        </div>
    );
};

const map_blank_index_to_all_options = function(question: OutputQuestion): Map<number,Map<string, Array<OutputOption>>> {
    const mappings = new Map<number, Map<string, Array<OutputOption>>>();
    question.blank_indices.forEach((blank_index: number, index) => {
        const field = question.template_components[blank_index] as FieldTemplateComponent;
        const options = question.field_options.get(field.field_name)!;
        const mapping = new Map<string, Array<OutputOption>>();
        mappings.set(blank_index, mapping);
        options.forEach((option) => {
            const value = option.value;
            const arr = mapping.get(value) || new Array<OutputOption>();
            if (arr.length === 0) {
                mapping.set(value, arr);
            }
            arr.push(option);
        });
    });
    return mappings;
}

const order_blank_indices_by_dominance = function(mappings: Map<number,Map<string, Array<OutputOption>>>):[number, number] {
    return [...mappings.entries()].map(([blank_index, values_map]) => [blank_index, values_map.size]).sort((a,b) => a[1] - b[1]).map(([blank_index, _]) => blank_index) as [number, number];
}

const construct_correct_answers_lookup = function(question: OutputQuestion, ordered_blank_indices: [number, number]):Map<string,[number,number]> {
    // Extract correct options pairs
    const options_arrays = ordered_blank_indices.map(blank_index => question.field_options.get((question.template_components[blank_index] as FieldTemplateComponent).field_name)!) as [OutputOption[], OutputOption[]];
    const correct_options_zipped: [OutputOption, OutputOption][] = options_arrays[0].map((x, i) => [x,options_arrays[1][i]]);

    // Make string key for easy lookup
    const correct_answers = new Map<string,[number,number]>(correct_options_zipped.map(([dominant_option, non_dominant_option]) => {
        const key = `${dominant_option.value};${non_dominant_option.value}`;
        const values: [number, number] = [dominant_option.question_option_id, non_dominant_option.question_option_id];
        return [key, values]; 
    }));

    return correct_answers;
}

export const matching_answers = (question: OutputQuestion, form_state: object): Array<QuestionAnswer> => {
    
    const name_expression = new RegExp('([A-Za-z0-9/ \\-\\(\\)]+;[A-Za-z0-9/ \\-\\(\\)]+):(\\d+);(\\d+):(t|f)');
    let answer_count: number = 0;
    
    // Collect user selections
    const user_selections = new Map<string, [boolean, [number, number]]>(Object.entries(form_state).map(([name, selected]) => {
        const match = name_expression.exec(name);
        if (match) {
            const dominant_option_id = Number.parseInt(match[2]);
            const non_dominant_option_id = Number.parseInt(match[3]);
            const key = match[1];
            return [key, [selected, [dominant_option_id, non_dominant_option_id]]];
        } else {
            throw new Error(`Cannot parse name: ${name}`);
        }
    }));

    // Get list of correct answers
    const ordered_blank_indices = order_blank_indices_by_dominance(map_blank_index_to_all_options(question));
    const correct_answers_lookup = construct_correct_answers_lookup(question, ordered_blank_indices);

    // Two approaches
    // 1. Iterate correct and search if user selected it -> misses false positives
    // 2. Iterate user selections and submit their choices  -> misses false negatives
    // Need to combine

    // Iterate correct answers
    const answers = new Array<QuestionAnswer>();
    [...correct_answers_lookup.entries()].forEach(([key, [dominant_option_id, non_dominant_option_id]]) => {
        const answered_correctly = (user_selections.get(key) || [false])[0];
        answers.push(new QuestionAnswer(question.question_id, dominant_option_id, answer_count, answered_correctly))
        answers.push(new QuestionAnswer(question.question_id, non_dominant_option_id, answer_count, answered_correctly))
        answer_count++;
    });

    // Iterate over user selections
    [...user_selections.entries()].forEach(([key, value]) => {
        const selected = value[0];
        if (selected && correct_answers_lookup.get(key) === undefined) {
            const dominant_option_id = value[1][0];
            const non_dominant_option_id = value[1][0];
            answers.push(new QuestionAnswer(question.question_id, dominant_option_id, answer_count, false))
            answers.push(new QuestionAnswer(question.question_id, non_dominant_option_id, answer_count, false))
            answer_count++;
        }

    })
    return answers;
};

export default MatchingOptions;
