import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import API, { OutputQuestion } from '../api/api';

export default function NextQuestion() {
  let { topics_param } = useParams();
  let [question, setQuestion] = useState<OutputQuestion | null>(null);
  
  useEffect(() => {
    async function next_question() {
        const topics = topics_param!.split(',').map(x => Number.parseInt(x));
        API.next_question(topics)
        .then((question: OutputQuestion) => {
            setQuestion(question);
          })
    };
    next_question();
  }, [topics_param]);

  return (
    <div>
      {question && <Navigate to={`/topics/${topics_param}/question/${question.question_id}`} replace={true} />}
    </div>
  );
}
