import React, { useEffect, useState } from "react";
import { FieldTemplateComponent,OutputOption, OutputQuestion, QuestionAnswer } from '../../api/api';
import Option from './option';
import './multiple-choice-options.css';

interface IMultipleChoiceOptions {
    question: OutputQuestion;
    form: [object, (e: React.FormEvent<HTMLInputElement>) => void];
    answers: Array<QuestionAnswer> | null;
}
const MultipleChoiceOptions: React.FunctionComponent<IMultipleChoiceOptions> = ({question, form, answers}) => {
    //const initial = question.blank_indices.length > 0 ? (question.field_options.get((question.template_components[question.blank_indices[0]] as FieldTemplateComponent).field_name)!):([]);
    const [blank_options, set_blank_options] = useState<OutputOption[]>([]/*initial*/);
    const [form_state, handle_form_change] = form;
    
    useEffect(() => {
        if (question.blank_indices.length > 0)
        {
            set_blank_options(question.field_options.get((question.template_components[question.blank_indices[0]] as FieldTemplateComponent).field_name)!);
        }
    }, [question]);

    useEffect(() => {
        // Display answers here
    }, [answers]);

    return (
        <div className="multiple-choice-container">
            {blank_options.sort((a,b) => (a.value.localeCompare(b.value))).map((option: OutputOption) =>
            (<div key={option.question_option_id} className="multiple-choice-option">
                <label htmlFor={`option-${option.question_option_id}`}>
                    <div>
                        <input type="checkbox" disabled={answers ? true:false} value={form_state[`option-${option.question_option_id}`]} id={`option-${option.question_option_id}`} name={`option-${option.question_option_id}`} onChange={handle_form_change}/>
                    </div>
                    <div><Option option={option} field={question.template_components[question.blank_indices[0]] as FieldTemplateComponent}/></div>
                </label>
                <div className="answer">
                    {answers && 
                        <span className={(() => {
                            const id = `option-${option.question_option_id}`;
                            const answered_correctly = form_state[id] === option.is_correct;
                            return answered_correctly ? 'correct':(option.is_correct ? 'missed':'incorrect');
                        })()}>
                            {option.is_correct ? (form_state[`option-${option.question_option_id}`] ? '✓':'✗'):(form_state[`option-${option.question_option_id}`] ? '✗': '')}
                        </span>
                    }
                </div>
            </div>))}
        </div>
    );
};

export const multiple_choice_answers = (question: OutputQuestion, form_state: object): Array<QuestionAnswer> => {
    const option_expression = new RegExp('option-(\\d+)');
    let answer_count: number = 0;
    const blank_field = question.template_components[question.blank_indices[0]] as FieldTemplateComponent;
    const correct_answers = question.field_options.get(blank_field.field_name)!.filter(option => option.is_correct);
    const parsed_responses = new Map<number, boolean>(Object.entries(form_state).map((value: [string, boolean]) => {
        const match = option_expression.exec(value[0]);
        if (match) {
            const option_id = Number.parseInt(match[1]);
            return [option_id, value[1]];
        } else {
            throw new Error(`Error parsing: ${value[0]}`);
        }
    }));

    // Two approaches
    // 1. Iterate correct and search if user selected it -> misses false positives
    // 2. Iterate user selections and submit their choices  -> misses false negatives
    // Need to combine

    // 1. Iterate correct answers
    const answers: Array<QuestionAnswer> = correct_answers.map((correct_answer, index) => {
        const option_id = correct_answer.question_option_id;
        const answered_correctly = parsed_responses.get(option_id) || false;
        return new QuestionAnswer(question.question_id, option_id, answer_count++, answered_correctly);
    });

    // 2. Iterate user selections
    [...parsed_responses.entries()].forEach(([option_id, selected]) => {

        // Incorrect option chosen
        if (selected && correct_answers.findIndex((correct_option) => correct_option.question_option_id === option_id) === -1) {
            answers.push(new QuestionAnswer(question.question_id, option_id, answer_count++, false));
        }
    });

    return answers;
};

export default MultipleChoiceOptions;
