import React, { useEffect, useState } from "react";
import { Navigate, useOutletContext } from "react-router-dom";
import { MyContext } from "../app/app";
import './login.css';
import API from "../../api/api";
import { ErrorNavigateState, HTTPException } from "../../api/error";

export default function Login() {
  const context = useOutletContext<MyContext>();
  const [u_number, setUNumber] = useState<string>('');
  const [logged_in, setLoggedIn] = useState<boolean>(false);
  const [error_state, setErrorState] = useState<ErrorNavigateState | null>(null);
    
  useEffect(() => {

    // Set title
    context.setTitle("Login");
    context.setFooter(null);
    context.setRight(null);
  }, [context]);

  const handle_submit = (e: React.FormEvent<HTMLFormElement>) => {
    API.authorize({u_number: u_number}).then((response) => {
        setLoggedIn(true);
    }, (err: HTTPException) => {
        setErrorState({
          path: err.status === 401 ? "/login" : "/error",
          state: {
            status_text: err.statusText,
            reason: err.reason
          }
        });
    });
    e.preventDefault();
  };

  const handle_input_change = (e: React.FormEvent<HTMLInputElement>): void => {
    const target = e.currentTarget;
    const value = target.type === 'text' ? target.value : '';
    setUNumber(value);
  };

  return (
    <div className="login-container">
        {logged_in && <Navigate to="/" />}
        {error_state && <Navigate to={error_state.path} state={error_state.state}/>}
        <form onSubmit={handle_submit}>
            <fieldset>
                <legend>
                    <h3>Please login with your ANU student number</h3>
                </legend>
                <label htmlFor="unumber">
                    <span>Student number: </span>
                    <input type="text" name="unumber" id="unumber" placeholder="u1234567" value={u_number} onChange={handle_input_change}/>
                </label>
                <div className="privacy">
                  <h5>Privacy note:</h5>
                  <p>Student numbers are never stored. It is combined with a secret, then a one-way cryptographic hashing function produces a unique identifer. Only the unique identifier is stored.</p>
                </div>
                <div>
                  <input type="submit" value="Login"/>
                </div>
            </fieldset>
        </form>
    </div>
  );
}
