import React, { useEffect, useState } from "react";
import API, { TopicsTreeNode, UserStats } from '../../api/api';
import { Link, Navigate, useOutletContext } from "react-router-dom";
import { MyContext } from "../app/app";
import Stat from "./stat";
import TopicListStat from "./topics-list-stat";
import './dashboard.css';
import {getRelativeTime} from "../../api/relative-time";
import {ErrorNavigateState, HTTPException} from "../../api/error";

export default function Dashboard() {
  const context = useOutletContext<MyContext>();
  const [stats, setStats] = useState<UserStats | null>(null);
  const [topics, setTopics] = useState<TopicsTreeNode[] | null>(null);
  const [error_state, setErrorState] = useState<ErrorNavigateState | null>(null);
    
  useEffect(() => {

    console.log("Dashboard useEffect triggered");

    async function load_data() {
        /*const [stats, topics] = await */
        Promise.all([API.get_stats(), API.get_topics()]).then(([stats, topics]) => {
            setErrorState(null);
            setStats(stats);
            setTopics(topics);
        }, (err: HTTPException) => {
            setErrorState({
                path: err.status === 401 ? "/login" : "/error",
                state: {
                status_text: err.statusText,
                reason: err.reason
                }
            });
        });
    };

    load_data();

    // Set title
    context.setTitle("Dashboard");
    context.setFooter(null);
    context.setRight(null);
  }, []);

  const render_stats = () => {
    if (stats) {
        return (
            <div className="dashboard">
                {error_state && <Navigate to={error_state.path} state={error_state.state}/>}
                <section>
                    <h3>Overall Statistics</h3>
                    <Stat stat={stats.overall}/>
                </section>
                <section>
                    <h3>Most Recently Answered Questions</h3>
                    <ol>
                        {stats.most_recent.map((recent) => {
                            return (
                            <li key={`recent-${recent.question_id}`}>
                                <h4>{recent.template_string}: <Link to={`/question/${recent.question_id}`} ><div className="dashbaord-question-link">↗</div></Link></h4>
                                <ul>
                                    {[...recent.answers.entries()].sort((a,b) => Number.parseInt(a[0].substring(0,a[0].length-1)) - Number.parseInt(b[0].substring(0,b[0].length-1))).reverse().map(([answer_id, answer]) => {
                                        return (
                                        <li key={`answer-${answer_id}`} className="dashboard-answer-container">
                                            <div>
                                                <span>{[...answer.fields.entries()].sort((a,b) => a[0]-b[0]).map(([_, value]) => value).join(' - ')}</span>
                                            </div>
                                            <div>
                                                <span className={`answer ${answer.was_answered_correctly ? 'correct':(answer.is_correct ? 'missed':'incorrect')}`}>
                                                    {(answer.was_answered_correctly ? '✓': '✗')}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="time">{getRelativeTime(new Date(Number.parseInt(answer_id.substring(0,answer_id.length-1)) * 1000))}</span>
                                            </div>
                                        </li>
                                        );
                                    })}
                                </ul>
                            </li>
                            );
                        })}
                    </ol>
                </section>
                <section>
                    <h3>Worst Facts</h3>
                    <ol>
                        {stats.worst_facts.map(([fact_string, stat]) => {
                            return (
                            <li key={fact_string}>
                                <h4>{fact_string}</h4>
                                <Stat stat={stat}/>
                            </li>
                            ); 
                        })}
                    </ol>
                </section>
                <section>
                    <h3>Best Facts</h3>
                    <ol>
                        {stats.best_facts.map(([fact_string, stat]) => {
                            return (
                            <li key={fact_string}>
                                <h4>{fact_string}</h4>
                                <Stat stat={stat}/>
                            </li>
                            ); 
                        })}
                    </ol>
                </section>
                <section className="topics-stats-list">
                    <h3>Statistics by Topic</h3>
                    <TopicListStat topics={topics} stats={stats.topics} />
                </section>
                <section>
                    <h3>Statistics by Template</h3>
                    <ol>
                        {[...stats.templates.entries()].map(([template_string, stat]) => {
                            return (
                            <li key={template_string}>
                                <h4>{template_string}</h4>
                                <Stat stat={stat}/>
                            </li>
                            );
                        })}
                    </ol>
                </section>
            </div>
        );
    }

    return <div className="dashboard">{error_state && <Navigate to={error_state.path} state={error_state.state}/>}Loading stats... 🤞</div>
  };

  return render_stats();
}
