import React from "react";
import { Stat, TopicsTreeNode } from '../../api/api';
import StatComponent from "./stat";

interface ITopicListStat {
    topics: Array<TopicsTreeNode> | null;
    stats: Map<number,Stat>;
}
const TopicListStat: React.FunctionComponent<ITopicListStat> = ({topics, stats}) => {
    return (
        <ul>
            {topics?.map(node => {
                const id = `topic-stat-${node.topic.id}`;
                return (
                    <li key={id}>
                        <div className="topic-stat">
                            <div>
                                <h4>{node.topic.name}</h4>
                                <StatComponent stat={stats.get(node.topic.id) || null}/>
                            </div>
                            {node.children ? (
                                <TopicListStat topics={node.children} stats={stats}/>
                            ):('')}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default TopicListStat;
