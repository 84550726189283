import React, { useEffect, useState } from "react";
import { Navigate, useOutletContext, useParams } from "react-router-dom";
import API, { FieldTemplateComponent, LiteralTemplateComponent, OutputQuestion, TemplateComponentType, QuestionType, QuestionAnswer } from '../../api/api';
import MultipleChoiceOptions, { multiple_choice_answers } from "./multiple-choice-options";
import MatchingOptions, { matching_answers } from "./matching-options";
import './question.css';
//import FreeTextOption, { free_text_answers } from "./free-text-option";
import { MyContext } from "../app/app";
import { ErrorNavigateState, HTTPException } from "../../api/error";

export default function Question() {
  const context = useOutletContext<MyContext>();
  let { id_param } = useParams();
  let { topics_param } = useParams();
  let [question, setQuestion] = useState<OutputQuestion>(new OutputQuestion(NaN, QuestionType.MULTIPLE_CHOICE, [], [], new Map()));
  let [form_state, setFormState] = useState<object>({});
  let [answers, setAnswers] = useState<Array<QuestionAnswer> | null>(null);
  let [next_question, setNextQuestion] = useState<boolean>(false);
  const [error_state, setErrorState] = useState<ErrorNavigateState | null>(null);
  
  useEffect(() => {

    console.log("Question useEffect triggered");

    async function load_question() {
        if (id_param) {
            let id = Number.parseInt(id_param);
            API.get_question(id).then((question: OutputQuestion) => {
              if (question.question_id) {
                const link = `${window.location.protocol}//${window.location.host}/question/${question.question_id}`;
                context.setFooter({preamble: 'Share this question with peers using this link: ', text: link, link: link});
              } else {
                context.setFooter(null);
              }

              if (question.question_id) {
                const link = `${window.location.protocol}//${window.location.host}/question/${question.question_id}`;
                context.setFooter({preamble: 'Share this question with peers using this link: ', text: link, link: link});
              } else {
                context.setFooter(null);
              }

              setErrorState(null);
              setQuestion(question);
            }, (err: HTTPException) => {
              setErrorState({
                path: err.status === 401 ? "/login" : "/error",
                state: {
                  status_text: err.statusText,
                  reason: err.reason
                }
              });
            });
        }
    };
    load_question();

    // Set title, footer, and link
    context.setTitle(`Quiz`);
    if (topics_param) {
      context.setRight({text: '➤ Change Topics', link: '/topics/'});
    } else {
      context.setRight(null);
    }
    context.setFooter(null);

  }, [id_param, topics_param]);

  const handle_form_change = (e: React.FormEvent<HTMLInputElement>): void => {
    const target = e.currentTarget;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const update_object = {
      [name]: value
    };
    setFormState(form_state => ({
      ...form_state,
      ...update_object
    }));
  };

  const handle_submit = (e: React.FormEvent<HTMLFormElement>): void => {
    switch (question.question_type) {
      case QuestionType.MULTIPLE_CHOICE:
        const x = multiple_choice_answers(question, form_state);
        setAnswers(x);
        API.save_answers(x);
        break;
      case QuestionType.MATCHING:
        const y = matching_answers(question, form_state);
        setAnswers(y);
        API.save_answers(y);
        break;
      /*case QuestionType.FREE_TEXT:
        const z = free_text_answers(question, form_state);
        setAnswers(z);
        API.save_answers(z);
        break;*/
      default:
        throw new Error(`Unreachable. Unexpected question type: ${question.question_type}`);
    }
    e.preventDefault();
  }

  const handle_next_question = () => {
    setNextQuestion(true);
  };

  return (
    <div className="question">
      {error_state && <Navigate to={error_state.path} state={error_state.state}/>}
      {next_question && topics_param && <Navigate to={`/topics/${topics_param}/`} replace={true} />}
      <form onSubmit={handle_submit}>
        <fieldset>
          <legend className="question-legend">
            <div className="question-text">
              {
                question.template_components.map((component => {
                  if (component.type === TemplateComponentType.FIELD) {
                    const field = component as FieldTemplateComponent;
                    const is_blank = question.blank_indices.indexOf(field.index) !== -1;
                    const content = is_blank ? field.field_name : (question.field_options.get(field.field_name)![0].value);
                    return (<span key={field.index} className={`${field.type} ${is_blank ? 'blank': ''}`}>{content}</span>)
                  } else if (component.type === TemplateComponentType.LITERAL) {
                    const literal = component as LiteralTemplateComponent;
                    return (<span key={literal.index} className={literal.type}>{literal.string_literal}</span>)
                  } else {
                    throw new Error("unreachable");
                  }
                }))
              }
            </div>
          </legend>
          <div>
            {
              (() => {
                switch (question.question_type) {
                  case QuestionType.MULTIPLE_CHOICE:
                    return (<MultipleChoiceOptions question={question} form={[form_state, handle_form_change]} answers={answers}/>);
                  case QuestionType.MATCHING:
                    return (<MatchingOptions question={question} form={[form_state, handle_form_change]} answers={answers}/>);
                  /*case QuestionType.FREE_TEXT:
                    return (<FreeTextOption question={question} form={[form_state, handle_form_change]} answers={answers}/>);*/
                }
              })()
            }
          </div>
          <div className="buttons-container">
            <div>
              <input type="submit" value="Save and Check Answers" disabled={answers !== null}/>
            </div>
            {topics_param && <div>
              <button onClick={handle_next_question} disabled={answers === null}>Next Question</button>
            </div>}
          </div>
        </fieldset>
      </form>
    </div>
  );
}
