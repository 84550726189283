import React from "react";
import { FieldTemplateComponent,OutputOption } from '../../api/api';
import './option.css';

export interface ProcessEnv {
    [key: string]: string | undefined
}

declare const process: any;

interface IFreeTextOption {
    option: OutputOption;
    field: FieldTemplateComponent
}
const Option: React.FunctionComponent<IFreeTextOption> = ({option, field}) => {

    const render_option = () => {
        if (option.type === 'i' && option.image_basename) {
            return (
                <div className="option-container image-container">
                    <div className="background">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/${field.field_name}/${option.image_basename}.png`} alt={`${field.field_name} background`} />
                    </div>
                    <div className="foreground">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/${field.field_name}/${option.image_basename}-${option.value}.png`} alt={`${field.field_name} ${option.value}`} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="option-container">
                    <span>{option.value}</span>
                </div>
            );
        }
    };

    return render_option();
};

export default Option;
