import React from "react";
import { Stat } from '../../api/api';

export interface ProcessEnv {
    [key: string]: string | undefined
}

interface IStatComponent {
    stat: Stat | null;
}
const StatComponent: React.FunctionComponent<IStatComponent> = ({stat}) => {

    return (
    <ul className="stat">
        {stat && <li><span>Number Questions: </span><span>{stat.count}</span></li>}
        {stat && <li><span>Number of Answers: </span><span>{stat.answered} ({stat.count > 0 ? stat.answered/stat.count * 100 : 0}%)</span></li>}
        {stat && <li><span>Number Correct: </span><span>{stat.correct} ({stat.answered > 0 ? Math.round(stat.correct/stat.answered * 100): 0}%)</span></li>}
    </ul>
    );
};

export default StatComponent;
