import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import Question from './components/question/question';
import NextQuestion from './components/next-question';
import Topics from './components/topics/topics';
import App from './components/app/app';
import Dashboard from './components/dashboard/dashboard';
import AnatomaticError from './components/error/anatomatic-error';
import Login from './components/login/login';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/error',
        element: <AnatomaticError />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/topics/',
        element: <Topics />,
      },
      {
        path: '/topics/:topics_param',
        element: <NextQuestion />,
      },
      {
        path: '/topics/:topics_param/question/:id_param',
        element: <Question />,
      },
      {
        path: '/question/:id_param',
        element: <Question />,
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
/*root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);*/
root.render(
  <>
    <RouterProvider router={router}/>
  </>
);
