import React, { useState } from "react";
import { TopicsTreeNode } from '../../api/api';

interface ITopic {
    topics: Array<TopicsTreeNode> | null;
    form: [object, (name: string, value: boolean) => void];
}
const TopicList: React.FunctionComponent<ITopic> = ({topics, form}) => {
    const [form_state] = useState<object>(form[0]);
    const set_form_state = form[1];
    //const [form_state, set_form_state] = form;

    const handle_form_change = (e: React.FormEvent<HTMLInputElement>): void => {
        const target = e.currentTarget;
        const value = target.type === 'checkbox' ? target.checked : false;
        const name = target.name;
        set_form_state(name, value);
      };

    return (
        <ul>
            {topics?.map(node => {
                const id = `topic-${node.topic.id}`;
                return (
                    <li key={id}>
                        <div className="topic">
                            <label htmlFor={id}>
                                <div>
                                    <span>{node.topic.name}: </span>
                                </div>
                                <div>
                                    <input type="checkbox" name={id} id={id} value={form_state[id]} onChange={handle_form_change}/>
                                </div>
                            </label>
                            {node.children ? (
                                <TopicList topics={node.children} form={form}/>
                            ):('')}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default TopicList;
