import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import './app.css';

export interface LinkContext {
  text: string;
  link?: string;
  preamble?: string;
}

export interface MyContext {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setRight: React.Dispatch<React.SetStateAction<LinkContext | null>>;
  setFooter: React.Dispatch<React.SetStateAction<LinkContext | null>>;
}

export default function App() {
  let [title, setTitle] = useState<string>("Anatomatic");
  let [right, setRight] = useState<LinkContext | null>(null);
  let [footer, setFooter] = useState<LinkContext | null>(null);

const render_right = () => {
  if (right === null) {
    return (<Link to="/topics">Start New Quiz</Link>);
  } else {
    if (right.link) {
      return (<Link to={right.link}>{right.text}</Link>);
    } else {
      return (<span>{right.text}</span>);
    }
  }
};

const render_footer = () => {
  if (footer === null) {
    return (
      <footer>
        <div>Please consider completing survey here: </div>
        <Link to="/survey">Survey</Link>
      </footer>
    );
  } else {
    return (
      <footer>
        {footer.preamble && <div>{footer.preamble}</div>}
        {footer.link && <div><Link to={footer.link}>{footer.text}</Link></div>}
        {!footer.link && <div>{footer.text}</div>}
      </footer>
    );
  }
};

  return (
    <div id="app">
      <header>
        <nav>
          <div className="hamburger"><Link to="/">☰</Link></div>
          <div>
            <span className="title"><h1>{title}</h1></span>
          </div>
          <div>
            {render_right()}
          </div>
        </nav>
      </header>
      <main>
        <Outlet context={{setTitle: setTitle, setRight: setRight, setFooter: setFooter} as MyContext}/>
      </main>
      {render_footer()}
    </div>
  );
}
