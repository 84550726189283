import { HTTPException, UnknownError } from "./error";

declare type TemplateComponentResponse = FieldTemplateComponentResponse | LiteralTemplateComponentResponse;

export interface RecentQuestionAnswer {
    fields: Map<number,string>;
    was_answered_correctly: boolean;
    is_correct: boolean;
}
  
export interface RecentQuestion {
    question_id: number;
    template_string: string;
    answers: Map<string,RecentQuestionAnswer>;
}
  
export interface Stat {
    count: number;
    answered: number;
    correct: number;
}
  
export interface UserStats {
   overall: Stat;
   topics: Map<number, Stat>;
   templates: Map<string, Stat>;
   best_facts: Array<[string, Stat]>;
   worst_facts: Array<[string, Stat]>;
   most_recent: Array<RecentQuestion>;
}

interface LiteralTemplateComponentResponse {
    index: number;
    type: string;
    string_literal: string;
}

interface FieldTemplateComponentResponse {
    index: number;
    type: string;
    field_name: string;
    enable_blank: boolean;
    enable_text: boolean;
    enable_image: boolean;
}

export interface OptionResponse {
    question_option_id: number;
    type: string;
    value: string;
    is_correct: boolean;
}

interface QuestionResponse {
    question_id: number;
    blank_indices: number[];
    template_components: Array<TemplateComponentResponse>;
    field_options: Object;
}

interface TopicsTreeNodeResponse {
    topic: {
        id: number;
        name: string;
    };
    children: Array<TopicsTreeNodeResponse> | null;
}

export class OutputOption {
    constructor(public question_option_id: number, public type: string, public value: string, public is_correct: boolean, public image_basename: string | null) {

    }
}

export class OutputQuestion {
    constructor(public question_id: number, public question_type: QuestionType, public blank_indices: Array<number>, public template_components: Array<TemplateComponent>, public field_options: Map<string, Array<OutputOption>>) {

    }
}

export enum QuestionType {
    MULTIPLE_CHOICE,
    MATCHING,
    FREE_TEXT
}

export enum TemplateComponentType {
    //BLANK = 'blank',
    FIELD = 'field',
    LITERAL = 'literal'
}
  
export class TemplateComponent {
    constructor(public index: number, public type: TemplateComponentType) {

    }
}

export class LiteralTemplateComponent extends TemplateComponent {
    constructor(public index: number, public string_literal: string) {
        super(index, TemplateComponentType.LITERAL);
    }
}

export class FieldTemplateComponent extends TemplateComponent {
    constructor(public index: number, public field_name: string, public enable_blank: boolean, public enable_text: boolean, public enable_image: boolean) {
        super(index, TemplateComponentType.FIELD);
    }
}

export class QuestionOption {
    constructor(public all_possible_options_index: number, public correct_values_index: number, public is_correct: boolean, public is_blank: boolean, public type: string, public field: FieldTemplateComponent, public question_option_id: number = NaN) {
    }
}

export class FullQuestion {
    constructor(public question_id: number, public partial_question: PartialQuestion, public blank_field_indices: Array<number>, /*public correct_values_indices: Array<number>,*/ public field_options: Map<string, QuestionOption[]>) {

    }
}

export class PartialQuestion {

    constructor(
        public template_id: number,
        public template_components: Array<TemplateComponent>,
        //public fields: Array<Field>,
        public all_possible_values: Map<string, Array<string>>,
        public correct_values: Map<string, Array<number>>,
        public topics: Array<string>
    )
    {

    }
}

export class QuestionAnswer {
    constructor(
        public question_id: number,
        public option_id: number,
        public answer_number: number,
        public correctly_answered: boolean
    ) {

    }
}

export class TopicsTreeNode {
    constructor(public topic: Topic, public children: Array<TopicsTreeNode> | null)
    {

    }
}

export class Topic {
    constructor(public id: number, public name: string)
    {
        
    }
}

export interface LoginRequest {
    u_number: string;
}

export interface StatusResponse {
    status: string;
}

export const mapToObj = (m: Map<string, unknown>) => {
    return Array.from(m).reduce((obj : any, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
};

class Api {

    API_URI: string = /*'http://localhost:8787'; //*/ 'https://anatomatic.joshuathornton.com.au';
    questions: Map<number, OutputQuestion>;
    topics: Array<TopicsTreeNode> | null;

    constructor() {
        this.questions = new Map<number, OutputQuestion>();
        this.topics = null;
    }

    public async authorize(login: LoginRequest): Promise<StatusResponse> {
        return this.fetch_helper('/api/authorize/', login);
    }

    private async fetch_helper<T>(path, post_object?: object): Promise<T> {
        const method = post_object ? 'POST' : 'GET';
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            body: null,
            mode: "cors" as RequestMode, // no-cors, *cors, same-origin
        };
        if (post_object) {
            options.body = JSON.stringify(post_object, (key: string, value: any) => {if (value instanceof Map) { return mapToObj(value); }; return value;});
        }
        return fetch(`${this.API_URI}${path}`, options).then(async (response: Response): Promise<T> => {

            // No errors
            const contentType = response.headers.get('content-type');
            if (response.ok && contentType && contentType.includes('application/json')) {
                return response.json().then((value: T) => {
                    return Promise.resolve<T>(value);
                });
            }
            
            // Attempt to handle error
            else {
                if (contentType && contentType.includes('application/json')) {
                    return response.json().then((response: any) => {
                        if (typeof response === 'object' && 
                            (response as Object).hasOwnProperty('status') && 
                            (response as Object).hasOwnProperty('statusText') &&
                            (response as Object).hasOwnProperty('reason')) {
                            return Promise.reject(response as HTTPException);
                        } else {
                            return Promise.reject(new UnknownError(0, "API Error", response.toString()));
                        }
                    });
                } else {
                    return Promise.reject(new UnknownError(0, "API Error", response.toString()));
                }
            }
        }, (reason: any) => {
            return Promise.reject(new UnknownError(0, "API Error", reason.toString()));
        });
    }

    async handle_question_response(question_response: QuestionResponse): Promise<OutputQuestion> {
        
        const questions = this.questions;

        // Construct template components
        const template_components = new Array<TemplateComponent>();
        question_response.template_components.forEach(component => {
            if (component.type === 'field') {
                const field = component as FieldTemplateComponentResponse;
                template_components.push(new FieldTemplateComponent(field.index, field.field_name, field.enable_blank, field.enable_text, field.enable_image));
            } else if (component.type === 'literal') {
                const literal = component as LiteralTemplateComponentResponse;
                template_components.push(new LiteralTemplateComponent(literal.index, literal.string_literal));
            }
        });

        // Construct field options
        const field_options = new Map<string, OutputOption[]>(Object.entries(question_response.field_options));

        // Determine question type
        let question_type = QuestionType.MATCHING;
        if (question_response.blank_indices.length === 1){
            if (field_options.get((template_components[question_response.blank_indices[0]] as FieldTemplateComponent).field_name)!.length > 1) {
                question_type = QuestionType.MULTIPLE_CHOICE;
            } else {
                question_type = QuestionType.FREE_TEXT;
            }
        }

        // Construct question
        const question = new OutputQuestion(question_response.question_id, question_type, question_response.blank_indices, template_components, field_options);
        // Add to cache
        questions.set(question.question_id, question);

        return Promise.resolve(question);
    }

    public async get_question(question_id: number): Promise<OutputQuestion> {

        // Check if cached
        let question = this.questions.get(question_id);
        if (question) {
            return Promise.resolve(question!);
        }

        return this.fetch_helper<QuestionResponse>(`/api/question/${question_id}`)
        .then((r) => this.handle_question_response(r));
    }

    public async next_question(topics: Array<number>): Promise<OutputQuestion> {
        return this.fetch_helper<QuestionResponse>(`/api/question/next/${topics.join(',')}`)
        .then((r) => this.handle_question_response(r));
    }

    private static topics_tree_node_helper(response: Array<TopicsTreeNodeResponse>): Array<TopicsTreeNode> | null {
        if (response === null) {
            return null;
        }
        return response.map(t => new TopicsTreeNode(new Topic(t.topic.id, t.topic.name), Api.topics_tree_node_helper(t.children)));
    }

    public async get_topics(): Promise<Array<TopicsTreeNode>> {
        // Check cache
        if (this.topics) {
            return Promise.resolve(this.topics);
        }

        return this.fetch_helper<Array<TopicsTreeNodeResponse>>('/api/topics').then((topics_response: Array<TopicsTreeNodeResponse>) => {
            this.topics = Api.topics_tree_node_helper(topics_response);
            return Promise.resolve(this.topics);
        });
    }

    public async get_stats(): Promise<UserStats> {
        return this.fetch_helper(`/api/stats`)
        .then((stats_response: UserStats) => {

            // Manually reconstruct maps
            const topics = stats_response.topics as object;
            const templates = stats_response.templates as object;
            stats_response.topics = new Map<number, Stat>(Object.entries(topics).map(([key, value]) => [Number.parseInt(key), value]));
            stats_response.templates = new Map<string, Stat>(Object.entries(templates));

            stats_response.most_recent = stats_response.most_recent.map(recent => {
                const answers = recent.answers as object;
                recent.answers = new Map<string, RecentQuestionAnswer>(Object.entries(answers).map(([answer_id, answer] : [string, RecentQuestionAnswer]) => {
                    const fields = answer.fields as object;
                    return [answer_id, {
                        fields: new Map<number,string>(Object.entries(fields).map(([field_index, value]) => {
                            return [Number.parseInt(field_index), value];
                        })),
                        was_answered_correctly: answer.was_answered_correctly,
                        is_correct: answer.is_correct
                    }];
                }));
                return recent;
            });

            return Promise.resolve(stats_response);
        });
    }

    public async save_answers(answers: Array<QuestionAnswer> | null): Promise<any> {
        if (answers && answers.length) {
            return this.fetch_helper('/api/save-answers/', answers);
        }
    }
}

const API = new Api();
export default API;