import React, { useEffect, useState } from "react";
import API, { TopicsTreeNode } from '../../api/api';
import TopicList from "./topic-list";
import './topic.css';
import { Navigate, useOutletContext } from "react-router-dom";
import { MyContext } from "../app/app";
import { ErrorNavigateState, HTTPException } from "../../api/error";

export default function Topics() {
  const context = useOutletContext<MyContext>();
  const [topics, setTopics] = useState<Array<TopicsTreeNode> | null>(null);
  let [form_state, setFormState] = useState<object>({});
  let [selected_topics, setSelectedTopics] = useState<Array<number> | null>(null);
  const [error_state, setErrorState] = useState<ErrorNavigateState | null>(null);
    
  useEffect(() => {

    console.log("Topics useEffect triggered");

    async function load_topics() {
      API.get_topics().then((topics: TopicsTreeNode[]) => {
        setErrorState(null);
        setTopics(topics);
      }, (err: HTTPException) => {
        setErrorState({
          path: err.status === 401 ? "/login" : "/error",
          state: {
            status_text: err.statusText,
            reason: err.reason
          }
        });
      });
    };

    load_topics();

    // Set title
    context.setTitle("Choose Topics");
    context.setFooter(null);
    context.setRight(null);
  }, []);

  const set_form_state = function(name: string, value: boolean): void {
    const update_object = {
      [name]: value
    };
    setFormState(form_state => ({
      ...form_state,
      ...update_object
    }));
  }

  const handle_start_quiz = function() {
    setSelectedTopics(Object.entries(form_state).filter(([_, value]) => value === true).map(([key, _]) => Number.parseInt(key.slice(6))));
  };

  return (
    <div>
      {selected_topics && <Navigate to={`/topics/${selected_topics.join(',')}/`} />}
      {error_state && <Navigate to={error_state.path} state={error_state.state}/>}
      <div className="topics">
        {topics ? (
          <TopicList topics={topics} form={[form_state, set_form_state]}/>
        ):('')}
      </div>
      <div className="buttons-container">
            <div>
              <button onClick={handle_start_quiz} disabled={Object.entries(form_state).filter(([_, value]) => value === true).length === 0}>Start Quiz On Selected Topics</button>
            </div>
          </div>
    </div>
  );
}
