import React, { useEffect } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { MyContext } from "../app/app";
import { ErrorState } from "../../api/error";
import './anatomatic-error.css';

export default function AnatomaticError() {
  const context = useOutletContext<MyContext>();
  const location = useLocation();
  const { status_text, reason } = location.state as ErrorState;
    
  useEffect(() => {

    // Set title
    context.setTitle(status_text);
    context.setFooter(null);
    context.setRight(null);
  }, [context, location, reason, status_text]);

  return (
    <div className="error-container">
        <h2>{status_text || `Well this is awkward...`}</h2>
        <p>{reason || `Not only was there an error, but there were further errors with the error handling. As a result I have no further information to provide!`}</p>
    </div>
  );
}
